const hamburger = document.querySelector('.hamburger');
const navLink = document.querySelector('.mainNavigation-links');
const dataTableSelector = document.querySelector('.dataTable-selectors');

if ( hamburger ) {
  hamburger.addEventListener('click', () => {
    navLink.classList.toggle('hide');
  });
}

if ( dataTableSelector ) {
  dataTableSelector.addEventListener('click', (e) => {
    const target = e.target.dataset.target;
    const activeSelector = document.querySelector('.dataTable-selector.active');
    const activeTable = document.querySelector('.dataTable.active');

    if (activeSelector !== e.target) {
      activeSelector.classList.toggle('active');
      activeTable.classList.toggle('active');

      e.target.classList.toggle('active');
      document.getElementById(target).classList.toggle('active');
    }

    if ( document.querySelector('.frenchHornCallout') ) {
      if (target === 'brass') {
        document.querySelector('.frenchHornCallout').classList.remove('hidden')
        } else {
        document.querySelector('.frenchHornCallout').classList.add('hidden')
      }
    }
  });
}

document.querySelectorAll('[data-dropdown-trigger]').forEach((element) => {
  element.addEventListener('click', (event) => {
    const dropdown = event.target.dataset.dropdownTrigger;
    document.querySelector(`[data-dropdown-target=${dropdown}]`).classList.toggle('dropdownMenu-closed');
  });
})

document.addEventListener('click', function(event) {
  const specifiedElement = document.querySelector('.mainNavigation-dropdown');
  var isClickInside = specifiedElement.contains(event.target);
  if (!isClickInside) {
    document.querySelector(`[data-dropdown-target]`).classList.add('dropdownMenu-closed');
  }
});

document.getElementById('copyright-date').innerText = `${new Date().getFullYear()} `;
